import { Fragment } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import { useTranslation } from '@/hooks/useTranslation';
import { memo } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { MenuItem } from '@/types/menus';

export type SidebarProps = {
  sidebarOpen: boolean;
};

type SidebarItemProps = {
  menuItem: MenuItem;
  pathname: string;
  sidebarOpen: boolean;
  setSidebarOpen?: (expanded: boolean) => void;
};

export const SidebarItem = memo(
  ({ menuItem, pathname, sidebarOpen }: SidebarItemProps) => {
    const { t } = useTranslation();

    const classNames =
      'group relative flex justify-between items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-primary/30';

    if (!menuItem.children) {
      return (
        <li>
          <NavLink
            className={({ isActive }) =>
              clsx(classNames, { 'bg-primary/30': isActive })
            }
            to={menuItem.href || '#'}
          >
            <div className="flex gap-2">
              {menuItem.icon && (
                <menuItem.icon
                  className="h-6 w-6 shrink-0 text-primary"
                  aria-hidden="true"
                />
              )}
              {t(menuItem.name)}
            </div>
          </NavLink>
        </li>
      );
    }

    if (!menuItem.basePath) {
      return null;
    }

    return (
      <SidebarLinkGroup activeCondition={pathname.includes(menuItem.basePath)}>
        {(handleClick, open) => {
          return (
            <Fragment>
              <NavLink
                to="#"
                className={clsx(classNames, {
                  'bg-primary/30': pathname.includes(
                    menuItem.basePath ?? '&nt'
                  ),
                })}
                onClick={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              >
                <div className="flex gap-2">
                  {menuItem.icon && (
                    <menuItem.icon
                      className="h-6 w-6 shrink-0 text-primary"
                      aria-hidden="true"
                    />
                  )}
                  {sidebarOpen && t(menuItem.name)}
                </div>
                {open ? (
                  <ChevronUp
                    className={clsx('w-5 h-5', { hidden: !sidebarOpen })}
                  />
                ) : (
                  <ChevronDown
                    className={clsx('w-5 h-5', { hidden: !sidebarOpen })}
                  />
                )}
              </NavLink>

              <div
                className={clsx('translate transform overflow-hidden', {
                  'hidden h-0': !open,
                })}
              >
                <ul
                  className={clsx('flex flex-col', {
                    'mt-4 mb-5.5 gap-2.5 pl-6': open && sidebarOpen,
                  })}
                >
                  {menuItem.children?.map((subItem) => (
                    <li key={subItem.name}>
                      <NavLink
                        to={subItem.href || '#'}
                        end
                        className={({ isActive }) =>
                          clsx(
                            'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-gray-500 duration-300 ease-in-out hover:text-primary truncate',
                            {
                              '!text-primary': isActive,
                            }
                          )
                        }
                      >
                        {sidebarOpen && open && t(subItem.name)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </Fragment>
          );
        }}
      </SidebarLinkGroup>
    );
  }
);

SidebarItem.displayName = 'SidebarItem';
