import React from 'react';
import { AuthContext, AuthContextParams, UserType } from '../hooks/useAuth';
import { useUserStore } from '../stores/user';
import { useClientStore } from '@/stores/client';

const emptyUser = {
  _id: '',
  created: new Date(),
  name: '',
  email: '',
  password: '',
  loginEnabled: false,
  multiFactorEnabled: false,
  role: '',
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    login: stateLogin,
    logout: stateLogout,
    user,
    isLoggedIn,
    token,
  } = useUserStore();

  const selectedClient = useClientStore((state) => state.client);

  const value: AuthContextParams = {
    user: user || emptyUser,
    token: token || '',
    // TODO:
    client: selectedClient?._id ?? null,
    isLoggedIn,
    login: (newUser: UserType, token: string, callback: VoidFunction) => {
      localStorage.setItem('token', token);
      stateLogin(newUser, token);
      callback();
    },
    logout: (callback?: VoidFunction) => {
      stateLogout();
      if (callback) {
        callback();
      }
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.displayName = 'AuthProvider';

export default AuthProvider;
