import { Menu } from 'lucide-react';
import {
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from '@novu/notification-center';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../ui/button';
import { ColorSwitch } from './ColorSwitch';
import UserNav from './UserNav';
import { CommandMenu } from './CommandMenu';
import { LanguageSelector } from './LanguageSelector';
import { ClientSelector } from './ClientSelector';

export type HeaderProps = {
  onMenuClick?: () => void;
  sidebarOpen?: boolean;
};

export const Header = ({ onMenuClick }: HeaderProps) => {
  const navigate = useNavigate();

  const handleNotificationClick = useCallback(
    (message: IMessage) => {
      console.log(message);
      if (message.cta?.data?.url) {
        navigate(message.cta.data.url);
      }
    },
    [navigate]
  );

  return (
    <header className="sticky top-0 z-40 flex w-full bg-sidebar shadow-sm drop-shadow-1 dark:drop-shadow-none border-b">
      <div
        className={clsx(
          'flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11 bg-sidebar'
        )}
      >
        <div className="flex gap-4">
          <Button variant="outline" size="icon" onClick={onMenuClick}>
            <Menu />
          </Button>
          <CommandMenu />
        </div>
        <div className="flex items-center gap-x-4 lg:gap-x-6 pr-1">
          <ClientSelector />
          <LanguageSelector />
          <ColorSwitch />
          <PopoverNotificationCenter
            colorScheme={'light'}
            onNotificationClick={handleNotificationClick}
          >
            {({ unseenCount }) => (
              <NotificationBell unseenCount={unseenCount} />
            )}
          </PopoverNotificationCenter>
          <UserNav />
        </div>
      </div>
    </header>
  );
};
