export const AUDIT_LOGS = 'audit-logs';
export const FILES = 'files';
export const SCHEMAS = 'schemas';
export const SELLER = 'seller';
export const BUYER = 'buyer';
export const DOCUMENT = 'document';
export const SHIPMENTS = 'shipments';
export const SUMMARY = 'summary';
export const USERS = 'users';
export const STATS = 'stats';
export const TEMPLATES = 'templates';
export const EXPORT_XLSX = 'export/xlsx';
export const USER = 'user';
export const PROFILE = 'profile';
export const ORGANIZATION_SETTINGS = 'organization-settings';
export const CARBONE_URL = 'https://render.carbone.io/render';
export const CBAM = 'cbam';
export const AIS = 'ais';
export const MASTER_DATA = 'master-data';
export const CUSTOMS = 'customs';
