import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { produce } from 'immer';
import { ClientType } from '../schemas/client';
import { Menu } from '@/types/menus';

export type ClientTypeWithRole = ClientType & {
  role?: string;
};

export type ClientStore = {
  client: ClientTypeWithRole | null;
  availableClients: ClientTypeWithRole[];
  menu: Menu | null;
  setClient: (client: ClientTypeWithRole) => void;
  setMenu: (menu: Menu) => void;
  setAvailableClients: (clients: ClientTypeWithRole[]) => void;
};

export const useClientStore = create<ClientStore>()(
  devtools(
    persist(
      (set) => ({
        client: null,
        menu: null,
        availableClients: [],
        setClient: (client: ClientType | ClientTypeWithRole) => {
          set(
            produce((state: ClientStore): void => {
              localStorage.setItem('selected-client', client._id);
              state.client = client;
            })
          );
        },
        setMenu: (menu: Menu) => {
          set(
            produce((state: ClientStore): void => {
              state.menu = menu;
            })
          );
        },
        setAvailableClients: (clients: ClientTypeWithRole[]) => {
          set(
            produce((state: ClientStore): void => {
              state.availableClients = clients;
              if (!state.client) {
                state.client = clients[0];
              }
            })
          );
        },
      }),
      {
        name: 'client',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
