import { useMemo } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useAuthContext } from '@/hooks/useAuth';
import Modal from '../common/Modal';
import { useFileStore } from '@/stores/files';
import { baseURL } from '@/hooks/useApi';

export const DocumentModal = () => {
  const auth = useAuthContext();
  const selectedFile = useFileStore((state) => state.selectedFile);
  const clearSelectedFile = useFileStore((state) => state.clearSelectedFile);

  const headers = {
    Authorization: `Bearer ${auth.token}`,
  };

  const docs = useMemo(
    () => [
      {
        uri: `${baseURL}/shipments/${selectedFile?.shipmentId}/files/${selectedFile?._id}`,
        fileType: selectedFile?.filetype,
      },
    ],
    [selectedFile?._id, selectedFile?.filetype, selectedFile?.shipmentId]
  );

  return (
    <Modal modalType="documentModal" onClose={clearSelectedFile}>
      <DocViewer
        config={{
          header: { disableHeader: true, disableFileName: true },
          pdfVerticalScrollByDefault: true,
          pdfZoom: {
            defaultZoom: 1.1,
            zoomJump: 0.2,
          },
        }}
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        prefetchMethod="GET"
        requestHeaders={headers}
      />
    </Modal>
  );
};
