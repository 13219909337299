import { useMemo } from 'react';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import { useTranslation } from '@/hooks/useTranslation';
import { UserAvatar } from './UserAvatar';
import { Alignments, AvatarSizeClasses, AvatarSizes } from '@/types/layout';
import { UserType } from '@/hooks/useAuth';

const userAvatarSizes: Record<AvatarSizes, AvatarSizeClasses> = {
  default: {
    avatar: 'h-8 w-8',
    fallback: 'text-primary',
  },
  sm: {
    avatar: 'h-6 w-6',
    fallback: 'text-primary text-sm cursor-default',
  },
  lg: {
    avatar: 'h-12 w-12',
    fallback: 'text-primary text-xl',
  },
};

type UserAvatarWithCardProps = {
  user: UserType;
  size?: AvatarSizes;
  hideHoverCard?: boolean;
  alignment?: Alignments;
};

export function UserAvatarWithCard({
  user,
  size,
  hideHoverCard,
  alignment = 'start',
}: UserAvatarWithCardProps) {
  const { t } = useTranslation();
  const sizeClasses = useMemo(() => userAvatarSizes[size ?? 'default'], [size]);

  if (hideHoverCard) {
    return <UserAvatar user={user} size={sizeClasses} />;
  }

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div>
          <UserAvatar user={user} size={sizeClasses} title="" />
        </div>
      </HoverCardTrigger>
      <HoverCardContent align={alignment}>
        <div className="flex gap-4">
          <UserAvatar user={user} size={sizeClasses} />
          <div className="space-y-1">
            <h4 className="text-sm font-semibold">{user.name}</h4>
            {user.role && (
              <h4 className="text-sm font-semibold">
                {t(`users:roles.${user.role}`)}
              </h4>
            )}
            <p className="text-sm">{user.email}</p>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
