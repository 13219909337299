import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useMemo } from 'react';
import { useTheme } from '@/layouts/ThemeProvider';

type SizeNames = 'sm' | 'md' | 'lg';

export const sizes: Record<
  SizeNames,
  { container: string; icon: string; text: string }
> = {
  sm: { container: 'h-8', icon: 'h-8', text: '' },
  md: { container: 'h-16', icon: 'h-16', text: 'text-2xl' },
  lg: { container: 'h-32', icon: 'h-64', text: 'text-2xl' },
};

export type SidebarIconProps = {
  className?: HTMLElement['className'];
  showText?: boolean;
  size?: SizeNames;
  logoTheme?: 'dark' | 'light';
};

export default function SidebarIcon({
  className,
  showText = true,
  size = 'sm',
  logoTheme,
}: SidebarIconProps) {
  const sizeClasses = useMemo(() => sizes[size], [size]);
  const { theme } = useTheme();

  const currentLogoTheme = logoTheme ?? theme;
  const logo =
    currentLogoTheme === 'dark' ? '/cs1-logo-light.png' : '/cs1-logo-dark.png';

  return (
    <div
      className={clsx(
        `flex ${sizeClasses.container} shrink-0 items-center`,
        className
      )}
    >
      <Link to="/">
        <div className={clsx('flex items-center', sizeClasses.container)}>
          <img src={logo} className={clsx('w-auto', sizeClasses.icon)} />
          {showText && (
            <span className={clsx('font-bold text-center', sizeClasses.text)}>
              Cargoslip
            </span>
          )}
        </div>
      </Link>
    </div>
  );
}
