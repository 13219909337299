import { useState, ReactNode } from 'react';
import { ModalType, useModalStore } from '../stores/modals';
import { ModalContext } from '../contexts/ModalContext';

interface ModalProviderProps {
  children: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal(modalType: ModalType) {
    const setModal = useModalStore.getState().setModal;
    setModal(modalType, true);
    setIsModalOpen(true);
  }

  function closeModal(modalType: ModalType) {
    const setModal = useModalStore.getState().setModal;
    setModal(modalType, false);
    setIsModalOpen(false);
  }

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
}
