import { useEffect, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { useClientStore } from '@/stores/client';
import { useUserStore } from '@/stores/user';
import { useTranslation } from '@/hooks/useTranslation';
import { useLanguageStore } from '@/stores/language';
import { AppLayoutLoaderType } from './appLayoutLoader';
import { AppLayout } from './AppLayout';

export default function App() {
  const { i18n } = useTranslation();
  const { language } = useLanguageStore();
  const rootLoaderData = useRouteLoaderData('root') as AppLayoutLoaderType;
  const [initialized, setInitialized] = useState(false);
  const setUser = useUserStore((state) => state.setUser);
  const setAvailableClients = useClientStore(
    (state) => state.setAvailableClients
  );

  useEffect(() => {
    if (rootLoaderData.user) {
      setUser(rootLoaderData.user);
    }

    if (rootLoaderData.clients) {
      setAvailableClients(rootLoaderData.clients);
    }

    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }

    setInitialized(true);
  }, [i18n, language, rootLoaderData, setUser, setAvailableClients]);

  if (!initialized) {
    return null;
  }

  return <AppLayout />;
}
