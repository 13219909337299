import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import './index.css';
import './i18n';

import AppLayout from './layouts/App';
import Layout from './layouts/Default';

import { baseURL } from './hooks/useApi';
import {
  IS_SENTRY_AVAILABLE,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
} from './utils/sentry';
import RootErrorBoundary from './components/common/Error/RootErrorBoundary';
import { appLayoutLoader } from './layouts/appLayoutLoader';
import NotFoundDisplay from './components/common/Error/NotFoundDisplay';

let wrappedCreateBrowserRouter = createBrowserRouter;

if (IS_SENTRY_AVAILABLE) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    tracePropagationTargets: [baseURL],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  wrappedCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);
}

const router = wrappedCreateBrowserRouter([
  {
    id: 'root',
    Component: AppLayout,
    loader: appLayoutLoader,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        path: '/',
        lazy: () => import('@/screens/Home'),
      },
      {
        path: '/shipments',
        children: [
          {
            path: '/shipments',
            lazy: () => import('@/screens/shipment/ShipmentList'),
          },
          {
            path: '/shipments/create',
            lazy: () => import('@/screens/shipment/ShipmentCreate'),
          },
          {
            path: '/shipments/edit/:id',
            lazy: () => import('@/screens/shipment/ShipmentDetails'),
          },
          {
            path: '/shipments/summary',
            lazy: () => import('@/screens/shipment/ShipmentSummary'),
          },
        ],
      },
      {
        path: '/summary',
        children: [
          {
            path: '/summary',
            lazy: () => import('@/screens/shipment/CBAMSummary'),
          },
        ],
      },
      {
        path: '/inventory',
        children: [
          {
            path: '/inventory/products',
            children: [
              {
                path: '/inventory/products',
                lazy: () => import('@/screens/inventory/Products'),
              },
              {
                path: '/inventory/products/:id/view',
                lazy: () => import('@/screens/inventory/ShowProduct'),
              },
            ],
          },
          {
            path: '/inventory/procurements',
            children: [
              {
                path: '/inventory/procurements',
                lazy: () => import('@/screens/inventory/Procurements'),
              },
              {
                path: '/inventory/procurements/create',
                lazy: () => import('@/screens/inventory/CreateProcurement'),
              },
              {
                path: '/inventory/procurements/:id/view',
                lazy: () => import('@/screens/inventory/ShowProcurement'),
              },
            ],
          },
          {
            path: '/inventory/outbound',
            children: [
              {
                path: '/inventory/outbound',
                lazy: () => import('@/screens/inventory/Outbound'),
              },
              {
                path: '/inventory/outbound/create',
                lazy: () => import('@/screens/inventory/CreateOutbound'),
              },
              {
                path: '/inventory/outbound/:id/view',
                lazy: () => import('@/screens/inventory/ShowOutbound'),
              },
            ],
          },
          {
            path: '/inventory/transfers',
            children: [
              {
                path: '/inventory/transfers',
                lazy: () => import('@/screens/inventory/Transfers'),
              },
              {
                path: '/inventory/transfers/create',
                lazy: () => import('@/screens/inventory/CreateTransfer'),
              },
              {
                path: '/inventory/transfers/:id/view',
                lazy: () => import('@/screens/inventory/ShowTransfer'),
              },
            ],
          },
          {
            path: '/inventory/warehouses',
            children: [
              {
                path: '/inventory/warehouses',
                lazy: () => import('@/screens/inventory/Warehouses'),
              },
              {
                path: '/inventory/warehouses/create',
                lazy: () => import('@/screens/inventory/CreateWarehouse'),
              },
              {
                path: '/inventory/warehouses/:id/edit',
                lazy: () => import('@/screens/inventory/EditWarehouse'),
              },
            ],
          },
        ],
      },
      {
        path: '/cbam',
        children: [
          {
            path: '/cbam/reports',
            children: [
              {
                path: '/cbam/reports',
                lazy: () => import('@/screens/cbam/Reports'),
              },
              {
                path: '/cbam/reports/create',
                lazy: () => import('@/screens/cbam/CreateReport'),
              },
            ],
          },
        ],
      },
      {
        path: '/cbam-report-generator',
        children: [
          {
            path: '/cbam-report-generator',
            lazy: () => import('@/screens/cbam-report-generator/Create'),
          },
        ],
      },
      {
        path: '/resources',
        children: [
          {
            path: '/resources/:resource',
            lazy: () => import('@/screens/resource/ResourceList'),
          },
          {
            path: '/resources/:resource/create',
            lazy: () => import('@/screens/resource/ResourceCreate'),
          },
          {
            path: '/resources/:resource/:id/edit',
            lazy: () => import('@/screens/resource/ResourceEdit'),
          },
        ],
      },
      {
        path: '/user',
        children: [
          {
            path: '/user/profile',
            lazy: () => import('@/screens/user/Profile'),
          },
        ],
      },
      {
        path: '/organization',
        children: [
          {
            path: '/organization/settings',
            lazy: () => import('@/screens/organization/Settings'),
          },
        ],
      },
      {
        path: '/admin',
        children: [
          {
            path: '/admin/users',
            children: [
              {
                path: '/admin/users',
                lazy: () => import('@/screens/admin/team/Team'),
              },
              {
                path: '/admin/users/create',
                lazy: () => import('@/screens/admin/team/CreateUser'),
              },
              {
                path: '/admin/users/:id/edit',
                lazy: () => import('@/screens/admin/team/EditUser'),
              },
            ],
          },
          {
            path: '/admin/audit-log',
            lazy: () => import('@/screens/admin/AuditLog'),
          },
        ],
      },
      {
        path: '/root',
        children: [
          {
            path: '/root/clients',
            children: [
              {
                path: '/root/clients',
                lazy: () => import('@/screens/root/clients/ListClients'),
              },
              {
                path: '/root/clients/create',
                lazy: () => import('@/screens/root/clients/CreateClient'),
              },
              {
                path: '/root/clients/:id/edit',
                lazy: () => import('@/screens/root/clients/EditClient'),
              },
            ],
          },
          {
            path: '/root/cbam',
            children: [
              {
                path: '/root/cbam',
                lazy: () => import('@/screens/root/cbam/ListCBAMData'),
              },
              {
                path: '/root/cbam/import',
                lazy: () => import('@/screens/root/cbam/ImportCBAMData'),
              },
            ],
          },
        ],
      },
      // TODO: create a 404 page
      {
        path: '*',
        element: <NotFoundDisplay />,
      },
    ],
  },
  {
    path: '/accept-invitation',
    lazy: () => import('@/screens/auth/AcceptInvitation'),
  },
  {
    path: '/login',
    lazy: () => import('@/screens/auth/Login'),
  },
  {
    path: '/registration',
    lazy: () => import('@/screens/auth/Registration'),
  },
  {
    path: '/pre-registration',
    lazy: () => import('@/screens/auth/PreRegistration'),
  },
  // TODO: create a 404 page
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Layout>
      <RouterProvider router={router} />
    </Layout>
  </React.StrictMode>
);
