import { ReactNode } from 'react';
import { ThemeProvider } from './ThemeProvider';
import AuthProvider from '@/providers/AuthProvider';

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider defaultTheme="light">
      <AuthProvider>{children}</AuthProvider>
    </ThemeProvider>
  );
}
