import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useTranslation } from '@/hooks/useTranslation';
import i18n from '@/i18n';
import { Language, useLanguageStore } from '@/stores/language';
import { useCallback, useMemo } from 'react';
import { FlagIcon } from './FlagIcon';
import { Check } from 'lucide-react';

const flags: Record<Language, { component: JSX.Element; label: string }> = {
  'de-DE': {
    component: (
      <FlagIcon src="/flags/germany.png" alt={i18n.t('common:german')} />
    ),
    label: 'common:german',
  },
  'en-US': {
    component: <FlagIcon src="/flags/uk.png" alt={i18n.t('common:english')} />,
    label: 'common:english',
  },
  'hu-HU': {
    component: (
      <FlagIcon src="/flags/hungary.png" alt={i18n.t('common:hungarian')} />
    ),
    label: 'common:hungarian',
  },
  'pl-PL': {
    component: (
      <FlagIcon src="/flags/poland.png" alt={i18n.t('common:polish')} />
    ),
    label: 'common:polish',
  },
  'ro-RO': {
    component: (
      <FlagIcon src="/flags/romania.png" alt={i18n.t('common:romanian')} />
    ),
    label: 'common:romanian',
  },
  'ru-RU': {
    component: (
      <FlagIcon src="/flags/russia.png" alt={i18n.t('common:russian')} />
    ),
    label: 'common:russian',
  },
  'sk-SK': {
    component: (
      <FlagIcon src="/flags/slovakia.png" alt={i18n.t('common:slovak')} />
    ),
    label: 'common:slovak',
  },
  'sr-SR': {
    component: (
      <FlagIcon src="/flags/serbia.png" alt={i18n.t('common:serbian')} />
    ),
    label: 'common:serbian',
  },
  'tr-TR': {
    component: (
      <FlagIcon src="/flags/turkey.png" alt={i18n.t('common:turkish')} />
    ),
    label: 'common:turkish',
  },
  'uk-UA': {
    component: (
      <FlagIcon src="/flags/ukraine.png" alt={i18n.t('common:ukrainian')} />
    ),
    label: 'common:ukrainian',
  },
  'zh-CN': {
    component: (
      <FlagIcon src="/flags/china.png" alt={i18n.t('common:chinese')} />
    ),
    label: 'common:chinese',
  },
};

interface LanguageDropdownItemProps {
  onClick: () => void;
  code: Language;
  selected?: boolean;
}

const LanguageDropdownItem = ({
  onClick,
  code,
  selected,
}: LanguageDropdownItemProps) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem
      className="flex gap-2 items-center justify-between"
      onClick={onClick}
    >
      <div className="flex gap-2 items-center">
        {flags[code].component}
        {t(flags[code].label)}
      </div>
      {selected && <Check className="w-3 h-3" />}
    </DropdownMenuItem>
  );
};

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const currentLanguageCode = useMemo(
    () => currentLanguage.slice(0, 2),
    [currentLanguage]
  );

  const changeLanguage = useCallback(
    (lng: Language) => {
      const setLanguage = useLanguageStore.getState().setLanguage;
      if (currentLanguage === lng) {
        return;
      }
      i18n.changeLanguage(lng);
      setLanguage(lng);
    },
    [currentLanguage, i18n]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          title={t('common:selectLanguage')}
          className="uppercase flex gap-1 w-12"
        >
          {flags[currentLanguage].component}
          {currentLanguageCode}
          <span className="sr-only">{t('common:selectLanguage')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {Object.entries(flags).map(([code]) => (
          <LanguageDropdownItem
            key={code}
            onClick={() => changeLanguage(code as Language)}
            code={code as Language}
            selected={currentLanguage === code}
          />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
