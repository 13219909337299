import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NovuProvider } from '@novu/notification-center';
import * as Sentry from '@sentry/react';
import RequireAuth from '@/components/RequireAuth';
import { Sidebar } from '@/components/layout/Sidebar';
import { Header } from '@/components/layout/Header';
import { ModalProvider } from '@/providers/ModalProvider';
import { useAuthContext } from '@/hooks/useAuth';
import { useClientStore } from '@/stores/client';
import { IS_SENTRY_AVAILABLE, SENTRY_DSN } from '@/utils/sentry';
import { SchemaProvider } from './SchemaProvider';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import { DocumentModal } from '@/components/shipment/DocumentModal';
import { Sheet } from '@/components/ui/sheet';
// TODO: Add growthbook when feature flagging is enabled
/* import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import {
  GROWTHBOOK_API_HOST,
  IS_GROWTBOOK_DEV_MODE_ENABLED,
  IS_GROWTHBOOK_AVAILABLE,
} from '@/utils/growthbook'; */
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeTypes, useTheme } from './ThemeProvider';
import { TooltipProvider } from '@/components/ui/tooltip';
import { Toaster } from '@/components/ui/sonner';

/* const growthbook = new GrowthBook({
  enabled: IS_GROWTHBOOK_AVAILABLE,
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: 'sdk-eUpuuNwMmz2jIrVP',
  enableDevMode: IS_GROWTBOOK_DEV_MODE_ENABLED,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
}); */

const queryClient = new QueryClient();

const NOVU_APP_ID = import.meta.env.VITE_NOVU_APP_ID ?? 'yTRSqjhNDeml';

export function AppLayout() {
  const auth = useAuthContext();
  const { isXL } = useWindowDimensions();
  const { theme } = useTheme();

  const [sidebarOpen, setSidebarOpen] = useState(isXL);
  const toggleSidebar = useCallback(() => setSidebarOpen((prev) => !prev), []);
  const client = useClientStore((state) => state.client);
  const navigate = useNavigate();

  /* useEffect(() => {
    if (IS_GROWTHBOOK_AVAILABLE) {
      growthbook.loadFeatures();
    }
  }, []); */

  useEffect(() => {
    if (IS_SENTRY_AVAILABLE && auth.user._id !== '') {
      console.log('RUN SENTRY');

      Sentry.setUser({
        id: auth.user._id,
        username: auth.user.email,
        name: auth.user.name,
      });

      Sentry.init({
        dsn: SENTRY_DSN,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeSend(event: any) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id });
          }
          return event;
        },
      });
    }
  }, [auth]);

  if (!client) {
    navigate('/login');
  }

  return (
    // <GrowthBookProvider growthbook={growthbook}>
    <TooltipProvider>
      <DndProvider backend={HTML5Backend}>
        <Sheet>
          <RequireAuth>
            <ModalProvider>
              <QueryClientProvider client={queryClient}>
                <SchemaProvider>
                  <NovuProvider
                    subscriberId={auth.user._id}
                    applicationIdentifier={NOVU_APP_ID}
                  >
                    <div className="flex h-screen overflow-hidden">
                      <Sidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                      />
                      <div
                        className={clsx(
                          'absolute z-20 inset-0 bg-white bg-opacity-80 transition-opacity lg:hidden',
                          {
                            hidden: !sidebarOpen,
                            'bg-gray-700': theme === 'dark',
                          }
                        )}
                        onClick={toggleSidebar}
                      />

                      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        <Header
                          onMenuClick={toggleSidebar}
                          sidebarOpen={sidebarOpen}
                        />

                        <main className="h-full">
                          <Outlet />
                        </main>
                      </div>
                    </div>
                    <DocumentModal />
                  </NovuProvider>
                </SchemaProvider>
              </QueryClientProvider>
            </ModalProvider>
          </RequireAuth>
        </Sheet>
      </DndProvider>
      <Toaster richColors closeButton theme={theme as ThemeTypes} />
    </TooltipProvider>
    // </GrowthBookProvider>
  );
}
