import { Factory, ListPlus, Car, MenuSquare, LucideIcon } from 'lucide-react';
import { useMemo } from 'react';

export const menuIcons: Record<string, LucideIcon> = {
  factory: Factory,
  'list-plus': ListPlus,
  car: Car,
  default: MenuSquare,
};

export const menuIconNames = Object.keys(menuIcons);

type MenuIconProps = { icon?: string };

export function getMenuIcon({ icon }: MenuIconProps) {
  const defaultIcon = menuIcons['default'];

  return icon ? (menuIcons[icon] ?? defaultIcon) : defaultIcon;
}

export function MenuIcon(props: MenuIconProps) {
  const Icon = useMemo(() => getMenuIcon(props), [props]);

  return <Icon />;
}
