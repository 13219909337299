import { ReactNode, useCallback, useState } from 'react';
import { useSchemaStore } from '@/stores/schema';
import { Schema } from '@/types/schema';
import useGetSchemas from '@/hooks/useGetSchemas';

export const SchemaProvider = ({ children }: { children: ReactNode }) => {
  const [loaded, setLoaded] = useState(false);

  const onSchemaFetchSuccess = useCallback((data?: Schema[]) => {
    if (!data) return null;
    const setSchemas = useSchemaStore.getState().setSchemas;
    setSchemas(data);
    setLoaded(true);
  }, []);

  const { isLoading } = useGetSchemas({ onSuccess: onSchemaFetchSuccess });

  if (!loaded || isLoading) {
    return null;
  }

  return children;
};
