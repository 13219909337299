import { useCallback, useMemo } from 'react';
import { useTranslation as useBaseTranslation } from 'react-i18next';

export const useTranslation = (namespace?: string) => {
  const trans = useBaseTranslation(namespace);

  const withT = useCallback(
    (title: { [key: string]: string }) => {
      const currentLanguage = trans.i18n.language;
      return title[currentLanguage];
    },
    [trans.i18n.language]
  );

  return useMemo(() => {
    const t = (key: string, extensions?: Record<string, string | number>) => {
      if (extensions) {
        return trans.t(key, extensions) || key;
      }
      return trans.t(key) || key;
    };

    return { ...trans, t, withT };
  }, [withT, trans]);
};
