import Lottie from 'react-lottie';
import errorAnimation from '../../../assets/error_not_found_animation.json';

interface LottieProps {
  loop: boolean;
  autoplay: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  animationData: any;
  rendererSettings: {
    preserveAspectRatio: string;
  };
}

const defaultOptions: LottieProps = {
  loop: true,
  autoplay: true,
  animationData: errorAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function NotFoundDisplay() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-4">
      <Lottie options={defaultOptions} height={250} width={500} />
    </div>
  );
}
