import { useMemo } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import SidebarIcon from './SidebarIcon';
import { useTranslation } from '@/hooks/useTranslation';
import { useClientStore } from '@/stores/client';
import { useAuthContext } from '@/hooks/useAuth';
import { SidebarItem } from './SidebarItem';
import { getMenuIcon } from './MenuIcon';
import { MenuItem } from '@/types/menus';
import { useMenus } from '@/hooks/useMenus';

export type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (visible: boolean) => void;
};

export const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const auth = useAuthContext();
  const { t, i18n } = useTranslation();
  const { createNavigation } = useMenus();
  const location = useLocation();
  const { pathname } = location;

  const language = useMemo(
    () => i18n.resolvedLanguage ?? i18n.language,
    [i18n.resolvedLanguage, i18n.language]
  );

  const client = useClientStore((state) => state.client);
  const setMenu = useClientStore((state) => state.setMenu);

  const menuItems = useMemo(() => {
    const clientMenus = client?.menus ?? [];
    const newMenuItems: MenuItem[] = [];

    for (const menu of clientMenus) {
      const title = menu.definition?.title?.[language] ?? menu.title;
      const plural = menu.definition?.plural?.[language] ?? menu.title;

      const menuData: MenuItem = {
        name: plural,
        icon: getMenuIcon({ icon: menu.icon }),
      };

      if (menu.createEnabled) {
        menuData.basePath = `/resources/${menu.definitionName}`;
        menuData.children = [
          {
            name: t('common:menu.create', { resource: title.toLowerCase() }),
            href: `/resources/${menu.definitionName}/create`,
          },
          {
            name: t('common:menu.list', { resource: plural.toLowerCase() }),
            href: `/resources/${menu.definitionName}`,
          },
        ];
      } else {
        menuData.href = `/resources/${menu.definitionName}`;
      }

      newMenuItems.push(menuData);
    }

    const menu = createNavigation(
      newMenuItems,
      client?.modules || [],
      auth.user.role
    );

    setMenu(menu);

    return menu;
  }, [
    client?.menus,
    client?.modules,
    createNavigation,
    auth.user.role,
    setMenu,
    language,
    t,
  ]);

  return (
    <aside
      className={clsx(
        'absolute left-0 top-0 z-40 flex h-screen flex-col overflow-y-hidden duration-300 ease-linear border-r lg:static lg:translate-x-0 bg-sidebar',
        {
          'translate-x-0 w-72': sidebarOpen,
          '-translate-x-full w-0 opacity-0': !sidebarOpen,
        }
      )}
    >
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <SidebarIcon className="m-auto" size="md" showText={false} />
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="py-4 px-4 lg:mt-0 lg:px-6">
          <ul className="mb-6 flex flex-col gap-1.5">
            {menuItems.map((item) => (
              <SidebarItem
                key={item.name}
                pathname={pathname}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                menuItem={item}
              />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};
