import { useQuery } from '@tanstack/react-query';
import { ListResponseType, useApi } from './useApi';
import { Schema } from '../types/schema';
import { endpoints } from '../endpoints';
import { SCHEMAS } from '../queryKeys';
import { BASE_RESPONSE } from '@/constants';

interface QueryParams {
  onSuccess?: (data?: Schema[]) => void;
}

const useGetSchemas = ({ onSuccess }: QueryParams) => {
  const { sendRequest } = useApi<ListResponseType<Schema>>({
    url: endpoints.schemas({ _limit: 2000 }),
    method: 'get',
  });

  return useQuery({
    queryKey: [SCHEMAS],
    // TODO: @geri
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await sendRequest();
      return res ?? BASE_RESPONSE;
    },
    onSuccess: (data) => {
      if (data) {
        const { data: schemaData } = data;
        onSuccess?.((schemaData?.data ?? []) as Schema[]);
      }
    },
  });
};

export default useGetSchemas;
