import { ClientTypeWithRole, useClientStore } from '@/stores/client';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { useTranslation } from '@/hooks/useTranslation';
import { useCallback } from 'react';

export function ClientSelector() {
  const { t } = useTranslation();

  const setClient = useClientStore((state) => state.setClient);
  const selectedClient = useClientStore((state) => state.client);
  const availableClients = useClientStore((state) => state.availableClients);

  const handleChange = useCallback(
    (client: ClientTypeWithRole) => {
      setClient(client);

      // TODO: replace with react-router navigate when we clean the state handling
      window.location.replace('/');
    },
    [setClient]
  );

  if (availableClients.length < 2 || !selectedClient) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">{selectedClient.name}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="h-96 overflow-y-scroll">
        <DropdownMenuLabel>{t('common:organizations')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {availableClients.map((client) => (
          <DropdownMenuItem
            key={client._id}
            className="flex items-start"
            onSelect={() => handleChange(client)}
          >
            <div className="flex flex-1 flex-col">
              <span>{client.name}</span>
              <span className="text-muted-foreground">
                {t(`users:roles.${client.role}`)}
              </span>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
