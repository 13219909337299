import { useCallback, useEffect, useState } from 'react';
import {
  User as PersonIcon,
  Settings as SettingsIcon,
  Home as HomeIcon,
  Milestone,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import {
  CommandDialog,
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandShortcut,
  CommandItem,
} from '../ui/command';
import { useTranslation } from '@/hooks/useTranslation';

export function CommandMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const runCommand = useCallback((command: VoidFunction) => {
    setOpen(false);
    command();
  }, []);

  return (
    <>
      <Button
        variant="outline"
        className={cn(
          'relative w-full justify-start text-sm text-muted-foreground sm:pr-12 md:w-40 lg:w-64'
        )}
        onClick={() => setOpen(true)}
      >
        <span className="inline-flex">{t('common:search')}</span>
        <kbd className="pointer-events-none absolute right-1.5 top-1.5 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-xs font-medium opacity-100 sm:flex">
          <span className="text-sm">⌘</span>K
        </kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder={t('common:typeACommandOrSearch')} />
        <CommandList>
          <CommandEmpty>{t('common:noResultsFound')}</CommandEmpty>
          <CommandGroup heading={t('common:suggestions')}>
            <CommandItem onSelect={() => runCommand(() => navigate('/'))}>
              <HomeIcon className="mr-2 h-4 w-4" />
              <span>{t('common:dashboard')}</span>
            </CommandItem>
            <CommandItem
              onSelect={() => runCommand(() => navigate('/shipments'))}
            >
              <Milestone className="mr-2 h-4 w-4" />
              <span>{t('common:shipments')}</span>
            </CommandItem>
          </CommandGroup>
          <CommandGroup heading={t('common:settings')}>
            <CommandItem>
              <PersonIcon className="mr-2 h-4 w-4" />
              <span>{t('common:profile')}</span>
              <CommandShortcut>⌘P</CommandShortcut>
            </CommandItem>
            <CommandItem>
              <SettingsIcon className="mr-2 h-4 w-4" />
              <span>{t('common:settings')}</span>
              <CommandShortcut>⌘S</CommandShortcut>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
}
