import { useMemo } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { UserType } from '@/hooks/useAuth';
import { AvatarSizeClasses } from '@/types/layout';

type UserAvatarProps = {
  user: UserType;
  size: AvatarSizeClasses;
  title?: string;
};

export function UserAvatar({ user, size, title }: UserAvatarProps) {
  const fallback = useMemo(() => {
    const split = user.name.split(' ');
    if (split.length > 1) {
      return split[0][0] + split[1][0];
    }

    return user.name.substring(0, 2).toUpperCase();
  }, [user]);

  return (
    <Avatar className={size.avatar} title={title}>
      <AvatarImage src={user.profilePic} alt="profile-pic" />
      <AvatarFallback className={size.fallback}>{fallback}</AvatarFallback>
    </Avatar>
  );
}
