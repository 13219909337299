import clientsEN from './en/clients.json';
import clientsHU from './hu/clients.json';
import clientsSR from './sr/clients.json';
import clientsPL from './pl/clients.json';
import clientsDE from './de/clients.json';
import clientsRO from './ro/clients.json';
import clientsRU from './ru/clients.json';
import clientsSK from './sk/clients.json';
import clientsTR from './tr/clients.json';
import clientsUK from './uk/clients.json';
import clientsZH from './zh/clients.json';
import commonEN from './en/common.json';
import commonHU from './hu/common.json';
import commonSR from './sr/common.json';
import commonPL from './pl/common.json';
import commonDE from './de/common.json';
import commonRO from './ro/common.json';
import commonRU from './ru/common.json';
import commonSK from './sk/common.json';
import commonTR from './tr/common.json';
import commonUK from './uk/common.json';
import commonZH from './zh/common.json';
import countriesEN from './en/countries.json';
import countriesHU from './hu/countries.json';
import countriesSR from './sr/countries.json';
import countriesPL from './pl/countries.json';
import countriesDE from './de/countries.json';
import countriesRO from './ro/countries.json';
import countriesRU from './ru/countries.json';
import countriesSK from './sk/countries.json';
import countriesTR from './tr/countries.json';
import countriesUK from './uk/countries.json';
import countriesZH from './zh/countries.json';
import incotermsEN from './en/incoterms.json';
import incotermsHU from './hu/incoterms.json';
import incotermsSR from './sr/incoterms.json';
import incotermsPL from './pl/incoterms.json';
import incotermsDE from './de/incoterms.json';
import incotermsRO from './ro/incoterms.json';
import incotermsRU from './ru/incoterms.json';
import incotermsSK from './sk/incoterms.json';
import incotermsTR from './tr/incoterms.json';
import incotermsUK from './uk/incoterms.json';
import incotermsZH from './zh/incoterms.json';
import modulesEN from './en/modules.json';
import modulesHU from './hu/modules.json';
import modulesSR from './sr/modules.json';
import modulesPL from './pl/modules.json';
import modulesDE from './de/modules.json';
import modulesRO from './ro/modules.json';
import modulesRU from './ru/modules.json';
import modulesSK from './sk/modules.json';
import modulesTR from './tr/modules.json';
import modulesUK from './uk/modules.json';
import modulesZH from './zh/modules.json';
import shipmentEN from './en/shipment.json';
import shipmentHU from './hu/shipment.json';
import shipmentSR from './sr/shipment.json';
import shipmentPL from './pl/shipment.json';
import shipmentDE from './de/shipment.json';
import shipmentRO from './ro/shipment.json';
import shipmentRU from './ru/shipment.json';
import shipmentSK from './sk/shipment.json';
import shipmentTR from './tr/shipment.json';
import shipmentUK from './uk/shipment.json';
import shipmentZH from './zh/shipment.json';
import usersEN from './en/users.json';
import usersHU from './hu/users.json';
import usersSR from './sr/users.json';
import usersPL from './pl/users.json';
import usersDE from './de/users.json';
import usersRO from './ro/users.json';
import usersRU from './ru/users.json';
import usersSK from './sk/users.json';
import usersTR from './tr/users.json';
import usersUK from './uk/users.json';
import usersZH from './zh/users.json';

export const resources = {
  'en-US': {
    clients: clientsEN,
    common: commonEN,
    countries: countriesEN,
    incoterms: incotermsEN,
    modules: modulesEN,
    shipment: shipmentEN,
    users: usersEN,
  },
  'hu-HU': {
    clients: clientsHU,
    common: commonHU,
    countries: countriesHU,
    incoterms: incotermsHU,
    modules: modulesHU,
    shipment: shipmentHU,
    users: usersHU,
  },
  'sr-SR': {
    clients: clientsSR,
    common: commonSR,
    countries: countriesSR,
    incoterms: incotermsSR,
    modules: modulesSR,
    shipment: shipmentSR,
    users: usersSR,
  },
  'pl-PL': {
    clients: clientsPL,
    common: commonPL,
    countries: countriesPL,
    incoterms: incotermsPL,
    modules: modulesPL,
    shipment: shipmentPL,
    users: usersPL,
  },
  'de-DE': {
    clients: clientsDE,
    common: commonDE,
    countries: countriesDE,
    incoterms: incotermsDE,
    modules: modulesDE,
    shipment: shipmentDE,
    users: usersDE,
  },
  'ro-RO': {
    clients: clientsRO,
    common: commonRO,
    countries: countriesRO,
    incoterms: incotermsRO,
    modules: modulesRO,
    shipment: shipmentRO,
    users: usersRO,
  },
  'ru-RU': {
    clients: clientsRU,
    common: commonRU,
    countries: countriesRU,
    incoterms: incotermsRU,
    modules: modulesRU,
    shipment: shipmentRU,
    users: usersRU,
  },
  'sk-SK': {
    clients: clientsSK,
    common: commonSK,
    countries: countriesSK,
    incoterms: incotermsSK,
    modules: modulesSK,
    shipment: shipmentSK,
    users: usersSK,
  },
  'tr-TR': {
    clients: clientsTR,
    common: commonTR,
    countries: countriesTR,
    incoterms: incotermsTR,
    modules: modulesTR,
    shipment: shipmentTR,
    users: usersTR,
  },
  'uk-UA': {
    clients: clientsUK,
    common: commonUK,
    countries: countriesUK,
    incoterms: incotermsUK,
    modules: modulesUK,
    shipment: shipmentUK,
    users: usersUK,
  },
  'zh-CN': {
    clients: clientsZH,
    common: commonZH,
    countries: countriesZH,
    incoterms: incotermsZH,
    modules: modulesZH,
    shipment: shipmentZH,
    users: usersZH,
  },
};
