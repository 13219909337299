import React, { useContext } from 'react';
import { UserType } from '../schemas/user';

export type { UserType };

export type AuthContextParams = {
  user: UserType;
  token: string;
  client: string | null;
  isLoggedIn: boolean;
  login: (user: UserType, token: string, callback: VoidFunction) => void;
  logout: (callback?: VoidFunction) => void;
};

export const AuthContext = React.createContext<AuthContextParams>(null!);

export function useAuthContext(): AuthContextParams {
  return useContext<AuthContextParams>(AuthContext);
}
