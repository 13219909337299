/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import Ajv, { JSONSchemaType } from 'ajv';
import addFormats from 'ajv-formats';

import { Schema } from '../types/schema';

export function createValidator<T = unknown>(schema: JSONSchemaType<T>) {
  const ajv = new Ajv({
    allErrors: true,
    useDefaults: true,
    keywords: ['uniforms'],
  });
  addFormats(ajv);

  const validator = ajv.compile(schema);

  return (
    model: Record<string, unknown>
  ): Record<any, any> | null | undefined => {
    const isValid = validator(model);

    if (!isValid) {
      return { details: validator.errors };
    }
  };
}

export type SchemaStore = {
  schemas: Schema[];
  schemasByName: Record<string, Schema>;
  setSchemas: (schemas: Schema[]) => void;
  deleteSchema: (id: string) => void;
  updateSchema: (id: string, schema: Schema) => void;
};

export const useSchemaStore = create<SchemaStore>()(
  devtools(
    //persist(
    (set) => ({
      schemas: [],
      schemasByName: {},
      setSchemas: (schemas: Schema[]) => {
        set(
          produce((state: SchemaStore) => {
            state.schemas = schemas;

            state.schemasByName = schemas.reduce((acc, schema) => {
              acc[schema.name] = schema;
              return acc;
            }, {} as Record<string, Schema>);
          })
        );
      },
      deleteSchema: (id: string) => {
        set(
          produce((state: SchemaStore) => {
            state.schemas = state.schemas.filter((schema) => schema._id !== id);
          })
        );
      },
      updateSchema: (id: string, schema: Schema) => {
        set(
          produce((state: SchemaStore) => {
            state.schemas = state.schemas.map((oldSchema) =>
              oldSchema._id === id ? schema : oldSchema
            );
          })
        );
      },
    })
  )
);
