import { Menu, MenuItem } from '@/types/menus';
import {
  FolderRoot,
  Home as HomeIcon,
  Milestone,
  Shield,
  Warehouse,
  Building2,
  FolderKanban,
} from 'lucide-react';
import { useTranslation } from './useTranslation';

export const useMenus = () => {
  const { t } = useTranslation();

  const defaultMenus: Menu = [
    { name: t('common:menu.dashboard'), href: '/', icon: HomeIcon },
  ];

  const adminMenus: Menu = [
    {
      name: t('common:menu.organization-settings'),
      href: '/organization/settings',
      icon: Building2,
      roles: ['root', 'admin'],
    },
    {
      name: t('common:menu.admin'),
      basePath: '/admin',
      icon: Shield,
      // category: 'admin',
      roles: ['root', 'admin'],
      children: [
        { name: t('common:menu.team'), href: '/admin/users' },
        { name: t('common:menu.auditLog'), href: '/admin/audit-log' },
      ],
    },
    {
      name: 'Root',
      basePath: '/root',
      icon: FolderRoot,
      roles: ['root'],
      children: [
        { name: t('common:menu.clients'), href: '/root/clients' },
        { name: 'CBAM', href: '/root/cbam' },
      ],
    },
  ];

  const moduleMenus: Record<string, Menu> = {
    shipment: [
      {
        name: t('modules:shipment.name'),
        icon: Milestone,
        basePath: '/shipments',
        children: [
          {
            name: t('common:menu.addNewShipment'),
            href: '/shipments/create',
          },
          {
            name: t('common:menu.myShipments'),
            href: '/shipments',
          },
          {
            name: t('common:menu.summary'),
            href: '/shipments/summary',
          },
        ],
      },
    ],
    'inventory-management': [
      {
        name: t('modules:inventory-management.name'),
        icon: Warehouse,
        basePath: '/inventory',
        children: [
          {
            name: t('common:menu.products'),
            href: '/inventory/products',
          },
          {
            name: t('common:menu.procurements'),
            href: '/inventory/procurements',
          },
          {
            name: t('common:menu.outbound'),
            href: '/inventory/outbound',
          },
          {
            name: t('common:menu.warehouses'),
            href: '/inventory/warehouses',
          },
          {
            name: t('common:menu.transfers'),
            href: '/inventory/transfers',
          },
        ],
      },
    ],
    cbam: [
      {
        name: t('modules:cbam.name'),
        icon: FolderKanban,
        basePath: '/cbam',
        children: [
          {
            name: t('common:menu.summary'),
            href: '/summary',
          },
          {
            name: t('common:menu.reports'),
            href: '/cbam/reports',
          },
        ],
      },
    ],
    'cbam-report-generator': [
      {
        name: t('modules:cbam-report-generator.name'),
        icon: FolderKanban,
        href: '/cbam-report-generator',
        roles: ['root', 'admin'],
      },
    ],
  };

  const moduleMenusKeys = Object.keys(moduleMenus);

  function getModuleMenus(modules: string[]): Menu {
    return modules.reduce((acc, module) => {
      if (moduleMenusKeys.includes(module)) {
        return [...acc, ...moduleMenus[module]];
      }
      return acc;
    }, [] as Menu);
  }

  function createNavigation(
    items: MenuItem[],
    clientModules: string[],
    userRole: string
  ): Menu {
    const menus = [
      ...defaultMenus,
      ...getModuleMenus(clientModules),
      ...items,
      ...adminMenus,
    ].filter((menu) => {
      if (menu.roles) {
        return menu.roles.includes(userRole);
      }
      return true;
    });

    return menus;
  }

  return { createNavigation };
};
